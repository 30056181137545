import {
    Button,
    Form,
    Input,
    List,
    Space,
    Image,
    Tabs,
    TextArea,
    Dialog,
    Toast,
    Card,
    DatePicker, Grid
} from "antd-mobile";
import axios from "axios";
import {useCallback, useEffect, useRef, useState} from "react";
import React from 'react';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import {tab} from "@testing-library/user-event/dist/tab";
import {sleep} from "antd-mobile/es/utils/sleep";
import {GridItem} from "antd-mobile/es/components/grid/grid";


function App() {

    const [isLogin, setLogin] = useState(false)
    const [manage, setManage] = useState(false);
    const [tableData, setTableData] = useState([])
    const [newUsername, setNewUsername] = useState("")
    const [image, setImage] = useState(null);
    const [datePickerVisible, setDatePickerVisible] = useState(false)
    const [meetData, setMeetData] = useState({
        date: dayjs(new Date()),
        meetStr: ""
    })
    const [user, setUser] = useState({
        user: "",
        token: ""
    })


    const loadHistory = () => {
        axios.get("/mhw/history").then(resp => {
            console.log(resp.data)
            setMeetData({meetStr: resp.data.history, date: dayjs(new Date())})

            setImage(resp.data.image)
        })
    }
    const loadTableData = () => {
        axios.get("/mhw/listuser").then(resp => {
            if (resp.data.success) {
                setTableData(resp.data.list)
            }
        })
    }

    useEffect(() => {
        let username = Cookies.get('username')
        let token = Cookies.get('token')
        verify(username, token)
        loadHistory()
        loadTableData()
    }, []);

    const verify = (user, token) => {
        if (user && token) {
            axios.post("/mhw/verify", {user: user, token_client: token}).then(resp => {
                if (resp.data.success) {
                    setLogin(true)
                    setUser({
                        user: user,
                        token: token
                    })
                } else {
                    setLogin(false)
                }
            })
        }
    }
    const login = (formData) => {
        axios.post("/mhw/login", {user: formData.username, password: formData.password}).then(resp => {
            if (resp.data.success) {
                setLogin(true)
                setUser({
                    user: formData.username,
                    token: resp.data.token
                })
                Cookies.set('username', formData.username, {expires: 30})
                Cookies.set('token', resp.data.token, {expires: 30})
            }
        })
    }

    function logout() {
        Dialog.confirm({
            content: '确认要退出吗？',
            onConfirm: () => {
                Cookies.remove("username")
                Cookies.remove("token")
                setUser({user: "", token: ""})
                document.location = document.location
            },
        })
    }

    function deleteuser(v) {
        axios.get("mhw/deleteuser?username=" + v).then((resp) => {
            if (resp.data.success) {
                loadTableData()
                Toast.show({
                    icon: 'success',
                    content: '删除成功',
                    position: 'bottom',
                })
            } else {
                Toast.show({
                    icon: 'fail',
                    content: resp.data.message,
                    position: 'bottom',
                })
            }


        })
    }

    function adduser(v) {
        axios.get("mhw/adduser?username=" + v).then((resp) => {
            if (resp.data.success) {
                setNewUsername("");
                loadTableData()
                Toast.show({
                    icon: 'success',
                    content: resp.data.message,
                    position: 'bottom',
                })
            } else {
                Toast.show({
                    icon: 'fail',
                    content: resp.data.message,
                    position: 'bottom',
                })
            }
        })
    }

    function resetpwd(v) {
        axios.get("mhw/resetpwd?username=" + v).then((resp) => {
            if (resp.data.success) {
                loadTableData()
                Toast.show({
                    icon: 'success',
                    content: resp.data.message,
                    position: 'bottom',
                })
            } else {
                Toast.show({
                    icon: 'fail',
                    content: resp.data.message,
                    position: 'bottom',
                })
            }
        })
    }

    const doGenerate = () => {
        let username = user.user;
        let token = user.token
        if (username && token) {
            axios.post("/mhw/generate", {
                user: username,
                token_client: token,
                meetStr: meetData.meetStr,
                date: dayjs(meetData.date).format("YYYY-MM-DD")
            }).then(resp => {
                setImage(resp.data)
            })
        }
    }

    const base64ToBlob = (base64, type) => {
        let binary = atob(base64);
        let array = [];
        for (let i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
        }
        return new Blob([new Uint8Array(array)], {type: type});
    }

    return (
        <>
            <Tabs style={{display: isLogin ? "block" : "none"}} defaultActiveKey={"meetcodeGeneration"}>
                <Tabs.Tab
                    style={{display: (["xb", "victor"].includes(user.user)) && isLogin ? "block" : "none"}}
                    title='用户管理' key='usermgr'>
                    <div
                        style={{display: isLogin ? "block" : "none"}}
                    >
                        <List header={'用户管理'}
                        >
                            <List.Item
                                extra={<Button onClick={() => {
                                    let text = newUsername;
                                    if (text) {
                                        adduser(text)
                                    }
                                }}>添加用户</Button>}
                            >
                                <Input onChange={(t) => setNewUsername(t)} placeholder={"请输入新的用户名"}
                                       value={newUsername}></Input>
                            </List.Item>
                            {tableData.map(user => (
                                <List.Item
                                    key={user.user}
                                    description={user.password ? "密码已设置" : "密码未设置"}
                                    extra={[
                                        <Button color={"primary"}
                                                onClick={() =>
                                                    Dialog.confirm({
                                                        content: '是否确认重置\'' + user.user + '\'的密码？',
                                                        onConfirm: () => {
                                                            resetpwd(user.user)
                                                        },
                                                    })
                                                }
                                        >重置密码</Button>,
                                        <Space/>,
                                        <Button color={"danger"}
                                                disabled={['xb', 'victor'].includes(user.user)}
                                                onClick={() =>
                                                    Dialog.confirm({
                                                        content: '是否确认删除\'' + user.user + '\'',
                                                        onConfirm: () => {
                                                            deleteuser(user.user)
                                                        },
                                                    })
                                                }
                                        >删除</Button>]}
                                >
                                    {user.user}
                                </List.Item>
                            ))}
                        </List>
                    </div>
                </Tabs.Tab>
                <Tabs.Tab title='集会码生成' key='meetcodeGeneration'>
                    {/*generation panel*/}
                    <div>
                        <Card title={"日期:"}>
                            <div>
                                <Grid columns={3}>
                                    <GridItem span={2}>
                                        <Input value={dayjs(meetData.date).format("YYYY-MM-DD")}/>
                                    </GridItem>
                                    <GridItem span={1}>
                                        <Button size={"small"} onClick={(t) => setDatePickerVisible(true)}
                                                color={"primary"}>选择</Button>
                                    </GridItem>
                                </Grid>

                            </div>
                            <DatePicker
                                label={"test"}
                                visible={datePickerVisible}
                                onConfirm={(value) => {
                                    setMeetData({...meetData, "date": value})
                                    setDatePickerVisible(false)
                                }}
                                onClose={() => {
                                    setDatePickerVisible(false)
                                }}
                                defaultValue={new Date()}
                            >
                                {/*{meetData.date}*/}
                            </DatePicker>
                        </Card>
                        <Card title={"集会码，一行一个:"}>
                            <TextArea
                                style={{border: 2}}
                                rows={8}
                                value={meetData.meetStr}
                                onChange={
                                    (a) => {
                                        setMeetData({...meetData, meetStr: a})
                                    }
                                }
                            >
                                {meetData.meetStr}
                            </TextArea>
                        </Card>
                        <Button block color={"primary"} onClick={() => {
                            doGenerate()
                        }}>生成</Button>
                        <div style={{textAlign: "center"}}>
                            <Image src={image}
                                // onClick={() => {
                                //     try {
                                //         navigator.clipboard.write([
                                //             new ClipboardItem({
                                //                 'image/png': base64ToBlob(image.replace("data:image/jpg;base64,", ""), 'image/png')
                                //             })])
                                //         Toast.show({
                                //             icon: 'success',
                                //             content: '图片已复制',
                                //             position: 'bottom',
                                //         })
                                //     } catch (error) {
                                //         console.error(error);
                                //     }
                                // }}
                            />
                        </div>

                    </div>
                </Tabs.Tab>
                <Tabs.Tab title={
                    <Button color={"danger"}
                            style={{display: isLogin ? "block" : "none"}}
                            block
                            onClick={() => {
                                logout()
                            }}
                    >退出</Button>
                } key="asdf">

                </Tabs.Tab>
            </Tabs>
            {/*login panel*/
            }
            <div
                style={{display: isLogin ? "none" : "block"}}
                // style={{display: "none"}}
            >
                <Form
                    layout='horizontal'
                    autoComplete={"off"}
                    onFinish={login}
                    footer={
                        <Button block type='submit' color='primary' size='large'>
                            提交
                        </Button>
                    }
                >
                    <center>
                        <h1>集会码生成器</h1>
                        <h2>手机版</h2>
                    </center>

                    {/*<Form.Header>请先登陆</Form.Header>*/}
                    <Form.Item
                        name='username'
                        label='用户名'
                        rules={[{required: true, message: '用户名不能为空'}]}
                    >
                        <Input onChange={console.log} placeholder='请输入用户名'/>
                    </Form.Item>
                    <Form.Item name='password' label='密码' help='密码'>
                        <Input
                            placeholder='请输入密码'
                            rules={[{required: true, message: '密码不能为空'}]}
                            type={"password"}
                        />
                    </Form.Item>
                </Form>
            </div>
            {/*user manage*/
            }


        </>
    )
}

export default App;
